<script>
import AiNode from '@/assets/img/icons/runtimes/node.svg';
import AiJava from '@/assets/img/icons/runtimes/java.svg';
import AiPhp from '@/assets/img/icons/runtimes/php.svg';
import AiPython from '@/assets/img/icons/runtimes/py.svg';
import AiPio from '@/assets/img/icons/runtimes/pio.svg';
import AiVscode from '@/assets/img/icons/runtimes/vscode.svg';

export default {
    name: 'AppIcon',
    components: {
        'ai-node': AiNode,
        'ai-java': AiJava,
        'ai-php': AiPhp,
        'ai-python': AiPython,
        'ai-pio': AiPio,
        'ai-vscode': AiVscode,
    },

    props: {
        name: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
    },

    computed: {
        classObject() {
            return `icon ${this.className || ''}`;
        },
    },

    beforeCreate() {
        this.componentKeys = Object.keys(this.$options.components);
    },

    render(createElement) {
        const constructComponentName = () => {
            const componentName = `ai-${this.name}`;
            return this.componentKeys.includes(componentName)
                ? componentName
                : '';
        };

        return createElement('span', {
            class: `icon ${this.className || ''}`,
        }, [
            createElement(constructComponentName()),
        ]);
    },
};
</script>

<style lang="scss" scoped>
.icon {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply text-gray-700;
    fill: rgb(74, 85, 104);
    stroke: rgb(74, 85, 104);
    & > svg {
        width: 60%;
        height: 60%;
    }
    &.primary {
         fill: rgb(74, 85, 104);
    }
    &.secondary {
        fill: rgb(249, 160, 63);
    }
    &.light {
        fill: #a0aec0;
    }
    &.login {
        @apply h-full;
        @apply w-full;
    }
}
.runtime {
    &__node {
        fill: #44883e;
        stroke: #44883e;
        @apply text-color_node;
    }
    &__python {
        fill: #ffde57;
        stroke: #ffde57;
        @apply text-color_python;
    }
    &__java {
        fill: #df4652;
        stroke: #df4652;
        @apply text-color_java;
    }
    &__php {
        fill: #4f5b93;
        stroke: #4f5b93;
        @apply text-color_php;
    }
    &__pio {
        fill: #2c3e50;
        stroke: #2c3e50;
        @apply text-color_pio;
    }
    &__vscode {
        fill: #0065A9;
        stroke: #0065A9;
        @apply text-color_vscode;
    }
}
</style>
