<template>
    <nav class="navbar">
        <banner :message="banner.message"
                :message-type="banner.messageType"
                :is-shown="banner.isBannerShown"
                :banner-close="banner.bannerClose"
        />
        <div class="navbar__left">
            <div class="navbar__brand">
                <router-link to="/home" class="navbar__brand--link">
                    <img src="@/assets/img/logo.png" class="w-3/5">
                </router-link>
            </div>
            <div class="navigation">
                <router-link to="/courses/dashboard" class="navigation__item">
                    <font-awesome-icon icon="book" class="mt-3" />
                    <p class="text-sm ml-2 text-gray-700 font-semibold mt-3">
                        Tutorials
                    </p>
                </router-link>
            </div>
        </div>
        <div class="navbar__right">
            <div class="navbar__user">
                <button class="button logout" @click="logout">
                    <font-awesome-icon icon="sign-out-alt" class="text-sm mt-3" />
                </button>
                <div class="user">
                    <img v-if="user.profile"
                         :src="user.profile"
                         class="user__profile"
                    >
                    <h2 v-else class="user__name">
                        {{ firstLetter }}
                    </h2>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapActions } from 'vuex';
import Banner from '@/components/ui/Banner.vue';
import { bannerMixin } from '@/mixins/Banner';

export default {
    components: {
        Banner,
    },

    mixins: [bannerMixin],

    props: {
        view: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            user: {},
        };
    },
    computed: {
        firstLetter() {
            if (this.user.name) {
                const nameParts = this.user.name.split(' ');
                if (nameParts.length > 1) {
                    const firstName = nameParts[0];
                    const lastName = nameParts[1];
                    return `${firstName[0]}${lastName[0]}`;
                }
                if (nameParts.length === 1) {
                    const firstName = nameParts[0];
                    return firstName[0];
                }
            }
            return '';
        },
    },

    async created() {
        await this.getUser();
    },

    methods: {
        ...mapActions('auth', {
            getUserImpl: 'getUser',
            logoutImpl: 'logout',
        }),

        ...mapActions('sockets', [
            'endSocketConnection',
        ]),

        async getUser() {
            this.user = await this.getUserImpl();
        },

        async logout() {
            this.logoutImpl();
            this.endSocketConnection();
            this.$router.push({ path: '/' });
        },
    },
};
</script>

<style lang="scss" scoped>
.navbar {
    @apply fixed;
    @apply top-0;
    @apply left-0;
    @apply w-screen;
    @apply h-16;
    @apply shadow-xs;
    @apply z-10;
    @apply flex;
    @apply flex-row;
    @apply justify-between;
    @apply px-40;
    @apply bg-white;
    @apply text-gray-700;
}

.navbar__right,
.navbar__left{
    @apply h-full;
    @apply flex;
}

.navbar__brand,
.navbar__navigation,
.navbar__user {
    @apply w-20;
}

.navbar__brand {
    @apply w-20;
    @apply h-full;
    &--link{
        @apply w-full;
        @apply h-full;
        @apply flex;
        @apply items-center;
        @apply justify-center;
    }
}

.navbar__user {
    width: max-content;
    @apply flex;
    @apply h-full;
    @apply items-center;
}

.navigation {
    @apply flex;
    @apply flex-row;
    @apply ml-8;
}

.navigation__item {
    @apply h-full;
    @apply w-full;
    @apply flex;
    @apply items-center;
    @apply justify-evenly;
    @apply text-sm;
    @apply px-3;
    @apply text-gray-600;
    &:not(:last-of-type){
        @apply mr-5;
    }
}

.navigation__icon {
    @apply text-base;
}

.router-link-active {
    @apply border-b-2;
    @apply border-solid;
    @apply border-accent;
    @apply font-bold;
    @apply text-accent;
}

.user {
    @apply h-12;
    @apply w-12;
    @apply rounded-full;
    @apply overflow-hidden;
    @apply flex;
    @apply items-center;
    @apply justify-center;
    object-fit: contain;

}
.user__profile {
    @apply h-full;
}
.user__name {
    @apply font-medium;
    @apply text-base;
    @apply text-white;
    @apply rounded-full;
    @apply bg-accent;
    @apply h-12;
    @apply w-12;
    @apply grid;
    @apply place-items-center;
}

.logout,
.sync {
    @apply h-8;
    @apply w-8;
    @apply rounded-full;
    @apply overflow-hidden;
    @apply flex;
    @apply p-0;
    @apply items-center;
    @apply justify-center;
    @apply cursor-pointer;
    @apply bg-transparent;
    @apply mr-2;
    @apply text-gray-700;
    &:hover,
    &:focus{
        @apply text-accent;
    }
}
</style>
