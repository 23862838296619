/* eslint-disable import/prefer-default-export */

export const bannerMixin = {
    data() {
        return {
            banner: {
                message: '',
                messageType: '',
                isBannerShown: false,
            },
        };
    },

    methods: {
        renderBanner(type, message) {
            this.banner.messageType = type;
            this.banner.message = message;
            this.banner.isBannerShown = true;
            setTimeout(() => { this.banner.isBannerShown = false; }, 3000);
        },

        bannerClose() {
            this.banner.isBannerShown = false;
        },
    },
};
