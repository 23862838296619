<template>
    <div :class="generateBannerClass()">
        <div class="banner__message">
            <slot>
                <p>{{ message }}</p>
            </slot>
        </div>
        <div class="banner__actions">
            <button
                class="banner__button"
                @click="closeBanner"
            >
                <font-awesome-icon icon="times" />
            </button>
        </div>
    </div>
</template>

<script>
const Banner = {
    name: 'Banner',
    props: {
        message: {
            type: String,
            default: '',
        },
        messageType: {
            type: String,
            default: '',
        },
        isShown: {
            type: Boolean,
            default: false,
        },
        bannerClose: {
            type: Function,
            default: () => {},
        },
    },
    methods: {
        closeBanner() {
            const { bannerClose, isShown } = this;
            bannerClose(isShown);
        },
        generateBannerClass() {
            const isShown = this.isShown ? 'active' : null;
            const messageType = this.messageType ? this.messageType.toLowerCase() : '';
            let messageClass;

            switch (messageType) {
            case 'error':
                messageClass = 'error';
                break;
            case 'warning':
                messageClass = 'warning';
                break;
            case 'success':
                messageClass = 'success';
                break;
            default:
                messageClass = '';
            }

            const className = ['banner', isShown, messageClass].join(' ');

            return className;
        },
    },
};
export default Banner;
</script>

<style lang="scss" scoped>
.flex-center {
    @apply flex;
    @apply justify-center;
    @apply items-center;
}

.banner {
    @apply w-screen;
    @apply h-0;
    @apply overflow-hidden;
    @apply fixed;
    @apply bg-green-500;
    @apply top-0;
    @apply left-0;
    @apply px-40;
    z-index: 101;
    @apply flex;
    @apply justify-between;
    transition: all 0.3s ease-in-out;
    & > p {
        @apply text-white;
    }
    &.active {
        @apply h-16;
    }
    &.error {
        @apply bg-red-500;
        @apply text-white;
    }
    &.warning {
        @apply bg-orange-500;
        @apply text-white;
    }
    &.success {
        @apply bg-green-500;
        @apply text-white;
    }
}

.banner__message {
    @apply flex-1;
    @extend .flex-center;
    @apply text-base;
    & > p {
        @apply text-white;
        @apply font-medium;
    }
}

.banner__actions {
    width: 60px;
    @extend .flex-center;
    background-color: inherit;
}

.banner__button {
    @apply h-12;
    @apply w-12;
    @apply bg-white;
    @apply border-none;
    @apply cursor-pointer;
    @apply rounded-md;
    background-color: rgba(#000000, 0.02);
    &:hover {
        background: rgba(#000000, 0.1);
    }
    @extend .flex-center;
}
</style>
