<template>
    <div class="runtime" :class="className">
        <div class="runtime__current">
            <app-icon v-if="currentRuntime"
                      :name="currentRuntime"
                      :class="`runtime__${currentRuntime}`"
            />
            <font-awesome-icon v-else
                               icon="code"
                               class="icon icon__primary icon--md"
            />
        </div>
        <div class="runtime__select" @click="toggleDropdown">
            <p class="runtime__title" :class="currentRuntime">
                {{ currentRuntime || "select runtime" }}
            </p>
            <div class="runtime__icons">
                <font-awesome-icon v-if="!isSelectOpen"
                                   icon="angle-down"
                                   class="icon icon--md"
                />
                <font-awesome-icon v-else
                                   icon="angle-up"
                                   class="icon icon--md"
                />
            </div>
        </div>

        <div class="runtime__dropdown"
             :class="{ active: isSelectOpen }"
        >
            <button v-for="(runtime, index) in runtimes"
                    :key="index"
                    class="button runtime__button"
                    @click="setCurrentRuntime(runtime)"
            >
                <div class="runtime__button--left">
                    <app-icon :name="runtime" :class="`runtime__${runtime}`" />
                </div>
                {{ runtime }}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppIcon from '@/components/ui/AppIcon.vue';

export default {
    name: 'Runtimes',

    components: {
        AppIcon,
    },

    props: {
        className: {
            type: String,
            default: '',
        },
        onRuntimeChange: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            isSelectOpen: false,
        };
    },

    computed: {
        ...mapGetters('runtimes', [
            'currentRuntime',
            'runtimes',
        ]),
    },

    methods: {
        ...mapActions('runtimes', {
            setCurrentRuntimeImpl: 'setCurrentRuntime',
        }),

        setCurrentRuntime(runtime) {
            this.onRuntimeChange(runtime);
            this.setCurrentRuntimeImpl(runtime);
            this.toggleDropdown();
        },

        toggleDropdown() {
            this.isSelectOpen = !this.isSelectOpen;
        },
    },
};

</script>

<style lang="scss" scoped>
.runtime {
    @apply h-full;
    @apply rounded-md;
    @apply flex;
    @apply border;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply bg-white;
    @apply relative;
}
.runtime__current,
.runtime__select {
    @apply flex;
    @apply items-center;
    @apply overflow-hidden;
}
.runtime__current {
    @apply w-12;
    @apply py-2;
    @apply px-3;
    @apply bg-white;
    @apply border-r;
    @apply border-solid;
    @apply border-gray-300;
    @apply overflow-hidden;
    @apply justify-center;
    @apply rounded-l-md;
}
.runtime__select {
    @apply flex-1;
    @apply justify-between;
    @apply cursor-pointer;
    @apply py-2;
    @apply px-3;
}
.runtime__icons {
    @apply flex;
    @apply items-center;
    @apply text-sm;
}
.runtime__title {
    min-width: max-content;
    @apply text-center;
    @apply text-sm;
    @apply mr-2;
    @apply font-medium;
    @apply text-gray-700;
    &.node {
        @apply text-color_node;
    }
    &.java {
        @apply text-color_java;
    }
    &.python {
        @apply text-color_python;
    }
    &.php {
        @apply text-color_php;
    }
    &.pio {
        @apply text-color_pio;
    }
    &.vscode {
        @apply text-color_vscode;
    }
}

.runtime__dropdown {
    @apply absolute;
    @apply z-10;
    @apply top-full;
    @apply right-0;
    @apply w-full;
    @apply h-0;
    @apply overflow-hidden;
    @apply rounded-md;
    @apply shadow-sm;
    @apply flex;
    @apply flex-col;
    @apply bg-white;
    &.active {
        @apply border;
        @apply border-gray-300;
        height: max-content;
    }
}
.runtime__button {
    @apply h-10;
    @apply w-full;
    @apply bg-none;
    @apply justify-start;
    @apply text-gray-700;
    @apply text-sm;
    @apply font-normal;
    @apply flex;
    @apply items-center;
    @apply p-0;
    @apply pr-3;
    &:hover,
    &:focus {
        @apply bg-gray-100;
        @apply outline-none;
    }
    &--left {
        @apply w-12;
        & > .icon {
            @apply h-10;
        }
    }
}

.icon {
    @apply text-sm;
    @apply text-gray-700;
    &--md {
        @apply text-sm;
    }
    &__primary {
        @apply text-sm;
        @apply text-gray-500;
    }
}
</style>
